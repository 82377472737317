import React from "react"
import "./loading.scss"

export const Loading = props => {
    return (
        <div className="m-loading">
            <div className="m-loading-ring" />
        </div>
    )
  }
