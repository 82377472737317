import React from "react"
import "./nav.scss"

const Nav = (props) => {
  const dots = []
  for (let i = 1; i <= props.totalSteps; i += 1) {
    const isActive = props.currentStep === i
    const isCompleted = props.currentStep > i

    dots.push((
      <div className="navigation-steps-lines" key={`step-${i}`}>
        <p
           className={`dot ${isActive ? "active" : ""} ${isCompleted ? "step-done" : ""} ${i !== 0 && props.classNames ? props.classNames[i - 1] : ""}`}
           onClick={() => props.goToStep(i)}><span>{`${isCompleted || props.classNames ? "" : i}`}</span></p>
        <span className={`small`}>{i !== 0 && props.stepNames ? props.stepNames[i - 1] : ""}</span>
      </div>
    ))
  }
  return (
    <div className='navigation-steps'>{dots}</div>
  )
}

export default Nav