import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import "../components/scss/text.scss"
import "../components/scss/review.scss"
import { EncodedCookieDataStore } from "../services/storage/datastore/EncodedCookieDataStore"
import { Base64DecoderService } from "../services/storage/decoder/Base64DecoderService"
import { Base64EncoderService } from "../services/storage/encoder/Base64EncoderService"
import { GetOrderHandler } from "../services/api/GetOrder/GetOrderHandler"
import { Loading } from "../components/Modules/Loading/loading"
import { filterDataReview } from "../services/componentSwitcher/filterDataReview"
import { Button } from "../components/Layout/Buttons/buttons"
import Nav from "../components/Form/MultiStepForm/Nav"
import disclaimer from "../images/svg/icon-info-orange.svg"
import { GetApplicationNameHandler } from "../services/api/GetApplicationName/GetApplicationNameHandler"


class Review extends Component {

  constructor(props) {
    super(props)
    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    })

    this.state = {
      form: this.props.pageContext.json.fields,
      structure: this.props.pageContext.json.structure,
      data: {},
      applicationId: getOrder.order,
      reference:getOrder.reference,
      loading: false,
    }
  }

  componentDidMount() {
    const getOrder = new EncodedCookieDataStore({
      cookieName: "address_token",
      decoderService: new Base64DecoderService(),
      encoderService: new Base64EncoderService(),
    });
    if (!(this.state.applicationId === undefined || this.state.applicationId === "")) {
      const orderHandler = new GetOrderHandler({})
      const data = {
        applicationId: this.state.applicationId,
      }
      this.setState({
        loading: true,
      })

      const applicationNameHandler = new GetApplicationNameHandler({})
      let dataNameHandler = {}
      dataNameHandler.reference = this.state.applicationId

      Promise.all([orderHandler.customAction(data), applicationNameHandler.customAction(dataNameHandler)]).then(res => {
        let form = {}
        form["applicationName"] = res[1].application.formName
        form["applicationNumber"] = res[1].application.formDescription
        getOrder.reference=res[1].application.reference;
        this.setState({
          application: form,
          data: res[0].form,
          loading: false,
          reference:getOrder.reference
        })
      }).catch((body) => {
        this.setState({
          loading: false,
        })
      })
    } else {
      window.location.href = `/${this.props.pageContext.slug}`
    }
  }

  render() {
    let stepNames = []
    this.state.structure.steps.map(step => {
      let stepName = step.label.split(".")[1]
      stepNames.push(stepName)
    })
    let classNames = new Array(this.state.structure.steps.length).fill("step-done")

    const application = this.state.application
    const applicationTitle = application && !this.state.loading ?
      application.applicationNumber + " - " + application.applicationName + " Form" : undefined
    return (
      <Layout headerTitle={applicationTitle} hideMenuButton={false}>
        <Wrapper>
          <Nav classNames={classNames} totalSteps={this.state.structure.steps.length} stepNames={stepNames}/>
          {this.state.loading &&
          <Loading/>
          }
          <Wrapper classNames={`review__wrapper`}>
            <h1 className={`extra-large`}>Personal Application Review</h1>
            <p className={`group-title big`}>Review the data below and make edits if needed</p>
            {this.state.structure.steps.map((step, key) => {
              let groupTitle = step.label
              let fields = {}
              if (key === 2 || key === 3) groupTitle = `${groupTitle} (Optional)`
              return (
                <Wrapper key={"group" + key + step.name} classNames={`review__item`} id={"group" + key + step.name}>
                  <h2 className={`item__title big`}>{groupTitle}</h2>
                  {
                    step.groups.map((group, index) => {
                      if (!group.hiddenInReview) {
                        return (
                          <Wrapper key={group.name + index} id={group.name + index}>
                            {group.fields.map((field, key) => {
                              let question = this.state.form[field].label
                              let relative = (field == 'relative1Unknown' && 'Relative 1:' || field == 'relative2Unknown' && 'Relative 2:');
                              let response = filterDataReview(this.state.form[field], this.state.data[field]) === undefined ? "" : filterDataReview(this.state.form[field], this.state.data[field])
                              if (response == 0 && (field ==='relative2Unknown'||field==='relative1Unknown')) response = " "
                              if (response == 1 && (field ==='relative2Unknown'||field==='relative1Unknown')) response = "Yes"
                              if (field in fields) {
                                return
                              }
                              fields[field] = field
                              return (
                                <div className={`field`} key={`${field.name}${key}`}>
                                  {response.length !== 0 &&
                                    <p className={`field__question medium`}>{question == 'Unknown' ? `${relative}` : `${question}:`}</p>
                                  }
                                  {response.length !== 0 &&
                                    <p className={`field__answer medium`}>{(question == 'Unknown' && response == 'Yes') ? `Unknown` : `${response}`}</p>
                                  }
                                </div>
                              )
                            })}
                          </Wrapper>
                        )
                      }
                    })
                  }
                  <Wrapper classNames={`edit__wrapper`}>
                    <Button primaryAlt label={`Edit`} link={`/${this.props.pageContext.slug}#${step.name}`}/>
                  </Wrapper>
                </Wrapper>
              )
            })}
            <Wrapper isFlex classNames={`review__footer`}>
              <Wrapper classNames={`disclaimer__wrapper`}>
                <p className={`edit__disclaimer small`}><img src={disclaimer}/>Important: Make sure all the data entered
                  is correct before
                  continue.</p>
              </Wrapper>
              <Wrapper>
                <Button primary label={`Submit`} link={`/payment`}/>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </Layout>
    )
  }
}

export default Review