import { GetOrderAction } from './GetOrderAction';
import { AjaxHandler } from 'jsr-lib-http/dist/cjs/AjaxHandler';
import { LoggerService } from '../../logger/LoggerService';
import { ErrorHandle } from '../../errors/ErrorHandle';
import { GetOrderReducer } from './GetOrderReducer';

export class GetOrderHandler extends AjaxHandler {

    /**
     *
     * @param options {Object}
     * @param options.reducer {Class}
     */
    constructor (options) {
        options = options || {};
        super({
            apiPrefix: process.env.API_PREFIX,
            action: GetOrderAction,
            reducer: options.reducer || GetOrderReducer,
            loggerService: LoggerService,
            errorHandler: options.errorHandle || ErrorHandle.default,
            httpParams : {
                authorization : process.env.HTTP_SECRET_KEY,
                headers: {
                    'Accept-language': 'es'
                  }
            },
            // sessionStoreCookie: EncodedCookieDataStore,
            // tokenCookie: new EncodedCookieDataStore({
            //     cookieName: 'address_token',
            //     decoderService: new Base64DecoderService(),
            //     encoderService: new Base64EncoderService(),
            // })
        });
    }

}
