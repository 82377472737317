import endpointDefinition from '../../../internals/api/orders/get_order.json';
import { AjaxAction } from 'jsr-lib-http/dist/esm/AjaxAction';


export class GetOrderAction extends AjaxAction{

    constructor (endpointService) {
        super(endpointService, endpointDefinition);
    }

}
