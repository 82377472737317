import endpointDefinition from '../../../internals/api/application/get_application_name.json';
import { AjaxAction } from 'jsr-lib-http/dist/esm/AjaxAction';


export class GetApplicationNameAction extends AjaxAction{

    constructor (endpointService) {
        super(endpointService, endpointDefinition);
    }

}
